@tailwind base;
@tailwind components;
@tailwind utilities;



.pre-sale-timer {
 
  background: -moz-linear-gradient(bottom, rgba(2, 211, 254, 1) 0%, rgba(2, 211, 254, 0) 100%);
  background: -webkit-linear-gradient(bottom, rgba(2, 211, 254, 1) 0%, rgba(2, 211, 254, 0) 100%);
  background: linear-gradient(to top, rgba(2, 211, 254, 1) 0%, rgba(2, 211, 254, 0) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#02d3fe', endColorstr='#0002d3fe', GradientType=0);
  
}

.rang-slider-main {
  float: left;
  width: 100%;
  clear: both;
}

.rang-slider {
  float: left;
  width: 100%;
  clear: both
}

.rang-line {
  background: #ffffff;
  height: 3px;
  width: 100%;
  display: block;
  position: relative;
}

.rang-line:before,
.rang-line:after,
.rang-line span:before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 0;
  top: -6px;
  z-index: 1;
  display: block;
  position: absolute;
  content: "";
  border: 3px solid #fff;
  background: -moz-linear-gradient(top, #000000 0%, #3a3a3a 100%);
  background: -webkit-linear-gradient(top, #000000 0%, #3a3a3a 100%);
  background: linear-gradient(to bottom, #000000 0%, #3a3a3a 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#02d3fe', endColorstr='#0002d3fe', GradientType=0);
}

.rang-line span {
  position: absolute;
  left: 0;
  top: 0;
  background: #000;
  width: 40%;
  height: 100%;
}

.rang-line:before,
.rang-line span:before {
  border-color: #02d3fe;
}

.rang-line:after,
.rang-line span:before {
  left: auto;
  right: 0;
}

.rang-slider-toltip {
  float: left;
  width: 100%;
  clear: both;
  color: rgba(255, 255, 255, 0.4);
  text-align: right;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.rang-slider-toltip>span {
  display: inline-block;
}

.rang-slider-toltip>span:first-child {
  margin-left: 20%;
  float: left;
}

.rang-slider-toltip strong {
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 700;
}

.rang-slider-total {
  float: right;
  clear: both;
  display: flex;
  padding-right: 30px;
  padding-top: 30px;
}

.rangTotal {
  font-size: 42px;
  color: #ffffff;
  line-height: 42px
}

.rangTotal small {
  color: #ffffff;
  font-size: 20px;
  line-height: 34px;
  vertical-align: top;
}

.rang-slider-total span {
  padding-right: 10px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  text-align: right;
}

.rang-slider-total strong {
  display: block;
  color: #fff;
}

.token-sale-box {
  /*background: #02d3fe;*/
  background: rgb(1, 15, 27);
  background: linear-gradient(-90deg, rgba(1, 15, 27, 1) 0%, rgba(2, 211, 254, 1) 48%, rgba(1, 15, 27, 1) 100%);
  padding: 30px;
  border-radius: 8px;
  color: #1d1d1d;
  
}
.download-app {
  background: rgb(84, 84, 84);
  background: linear-gradient(-90deg, rgba(84, 84, 84, 1) 0%, rgba(30, 30, 31, 1) 100%);
  color: #fff;
  padding: 100px 0;
}


